// @import './variables.scss';

@mixin K-card {
  padding: 20px;
  background-color: white;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

// Textos
// Título dos inputs - 13px semibold
@mixin K-txt1 {
  font-size: 0.81rem;
  font-weight: 600;
}

// Texto grande - 18px medium
@mixin K-txt2 {
  font-size: 1.12rem;
  font-weight: 500;
}

// Texto grande2 - 18px semibold
@mixin K-txt3 {
  font-size: 1.12rem;
  font-weight: 600;
}

// Texto título - 26px light
@mixin K-txt4 {
  font-size: 1.62rem;
  font-weight: 300;
}

// Texto pequeno - 14px light
@mixin K-txt5 {
  font-size: 0.87rem;
  font-weight: 300;
}

@mixin K-titulo1 {
  margin: 0;
  font-size: 1.12rem;
  font-weight: 600;
  text-align: center;
  border-top: 1px solid $k-azul-100;
  color: $k-azul-100;
  border-bottom: 1px solid $k-azul-100;
  padding: 10px 0;
}

// Inputs
// Texto
@mixin K-txtInput {
  margin-top: 2.5rem;
  position: relative;

  @include media(tab-land) {
    margin-top: 2.1rem;
  }

  &__label {
    @include K-txt1;
    text-transform: uppercase;
    color: $k-cinza-900;
  }

  input {
    padding: 0.5rem 0;
  }

  // ajuste do select para ficar com tamanho igual aos outros...
  select {
    padding-top: 1px;
    padding-bottom: 4px;
  }

  &__campo {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #bbb;
    width: 100%;
    transition: all 50ms ease-out;
    box-sizing: content-box;

    &:focus {
      border-bottom: 1px solid $k-azul-500;
    }
  }
}

// Checkbox
@mixin K-checkbox($type: checkbox) {
  display: block;
  position: relative;
  padding-left: 30px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Remove o checkbox default
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  // Checkbox custom
  .selector {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid $k-cinza-500;
    border-radius: 3px;
    background-color: white;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  @if $type == radio {
    .selector {
      border-radius: 10rem;
    }
  }

  // Cor de background no hover
  &:hover input ~ .selector {
    background-color: lighten($k-cinza-100, 10%);
  }

  // Cor de background do elemento selecionado
  input:checked ~ .selector {
    background-color: #2196f3;
    border-color: #2196f3;
    transition: all 0.2s;
  }

  // Exibe o checkmark quando selecionado
  input:checked ~ .selector:after {
    display: block;
  }
}

// Botões
@mixin K-botao {
  font-size: 0.95rem;
  font-weight: 500;
  border: 0;
  color: white;
  height: 2.5rem;
  width: 9rem;
  border-radius: 4px;
  line-height: 1;

  span:first-child {
    margin-right: 0.4rem;
  }

  span:last-child {
    margin-left: 0.4rem;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(95%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    filter: brightness(100%);
  }

  @include media(tab-port) {
    font-size: 0.82rem;
  }

  @include media(phone) {
    width: 7.2rem;
    font-size: 0.8rem;
  }
}
