@import './global';
@import '~@ng-select/ng-select/themes/material.theme.css';

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  outline: none;
  margin: 0;
  padding: 0;
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
}

html,
body {
  background-color: #f9f9f9;
  font-size: 16px;

  @include media(tab-port) {
    font-size: 15px;
  }

  @include media(phone) {
    font-size: 14px;
  }
}

.conteudo {
  margin-bottom: 6rem;
}

.k-container {
  margin: {
    right: auto;
    left: auto;
  }
  width: 70%;
  max-width: 55rem;
  position: relative;
  z-index: 1;

  @include media(tab-port) {
    width: 80%;
    padding: 0 0.5rem;
  }
  @include media(phone) {
    width: 100%;
  }
}

.principal {
  background: #fff;
  margin: {
    top: 3rem;
    // bottom: 6rem;
  }
  padding: 3.7rem;
  border-radius: 0.5rem;
  box-shadow: rgba(50, 50, 50, 0.1) 2px 2px 30px -12px;

  @include media(tab-port) {
    margin-top: 2rem;
    padding: 2rem;
    box-shadow: rgba(50, 50, 50, 0) 2px 2px 30px -12px;
  }

  @include media(phone) {
    margin-top: 1.5rem;
    padding: 1.5rem 1rem;
  }

  .titulo {
    @include K-txt2;
    color: $k-cinza-700;
  }
}

.navigate-button {
  display: flex;
  justify-content: space-between;
  margin: 2.7rem 0 0;

  button {
    &:only-child {
      margin-left: auto;

      @include media(phone) {
        margin-left: initial;
        width: 100%;
      }
    }
  }

  .botaoContinuar {
    @include K-botao;
    background-color: $k-azul-500;
  }

  .botaoVoltar {
    @include K-botao;
    background-color: $k-cinza-300;
  }
}

.container {
  padding: 0 12rem;
  margin: 0;

  @include media(tab-port) {
    padding: 0 0.8rem;
  }
}

.btnInativo {
  opacity: 0.5;
  pointer-events: none;
}

.btnCarregando {
  pointer-events: none;

  i {
    animation: spin-animation 1s infinite;
    display: inline-block;
  }

  span {
    display: none;
  }
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

// Erro no input
.iptTxtErro {
  input {
    border-bottom-color: red !important;
  }
}

// ng-select

.container-code-number {
  display: flex;
  align-items: flex-end;
}

.ng-select.code {
  padding-bottom: 0;
  width: 7rem;
  margin-right: 1rem;
}

.ng-dropdown-panel.code {
  min-width: 200px !important;
  box-shadow: rgb(50 50 50 / 17%) 2px 2px 20px -5px;
  border: solid 1px #ccc;
}

.ng-select.code .label-img {
  width: 1.3rem;
}

.ng-select.code .label-code {
  font-size: 0.85rem;
  margin-left: 5px;
  font-weight: 600;
}

.ng-select.code .label-name {
  font-size: 0.8rem;
}

.ng-dropdown-panel .option-img {
  width: 1.5rem;
}

.ng-dropdown-panel .option-name {
  font-size: 0.85rem;
  margin: 0 7px;
}

.ng-dropdown-panel .option-code {
  font-size: 0.8rem;
  font-weight: 500;
}

.box-title {
  padding: 0 0.2rem;
  margin: 1rem 0;
  height: 3rem;

  i {
    font-size: 28px;
    margin-right: 0.9rem;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #343434;
    box-shadow: transparent 0 0 0;
    backdrop-filter: blur(10px);
    transition: all 400ms ease;

    span {
      font-weight: 700;
    }
  }

  .inner-box {
    display: flex;
    align-items: center;
  }
}

.box-child {
  i,
  span {
    color: $child;
  }
}

.box-responsible {
  i,
  span {
    color: $responsible;
  }
}

.box-financeResponsible {
  i,
  span {
    color: $financeResponsible;
  }
}

h4.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 4rem;
  padding-top: 1rem;
  box-shadow: rgba(50, 50, 50, 0.35) 0 4px 10px -8px;
  background-color: rgba(255, 255, 255, 0.75);

  .inner-box {
    width: calc(70%);
    padding: 0 3.9rem;
    max-width: 55rem;
    margin: auto;

    @include media(tab-port) {
      width: calc(80%);
      padding: 0 2.7rem;
    }
    @include media(phone) {
      width: calc(100%);
      padding: 0 1.7rem;
    }
  }
}

.version {
  font-size: 0.7rem;
  text-align: right;
  color: #aaa;
  margin: 0;
  position: fixed;
  bottom: 0.3rem;
  left: 1rem;
  z-index: 0;
}
